<template>
  <v-row>
    <v-card class="my-0 ml-3 d-flex flex-column">
      <v-navigation-drawer
        v-model="drawer"
        width="350"
        :mini-variant.sync="mini"
        permanent
        style="height: 85vh"
      >
        <v-list-item class="px-3 mt-2">
          <v-list-item-avatar>
            <v-img src="../assets/images/wb-square.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-title>
            WORLD BANK
          </v-list-item-title>

          <v-btn
            icon
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list class='pt-0'>
          <v-list-group 
             prepend-icon="mdi-database"
             no-action
             :value="true"
             active-class="font-weight-bold blue lighten-5"
            >
               <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title >Projects</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-card
                outlined
                shaped
                tile
              >
              <div class="mb-2">
                   <v-card-subtitle>       
                     <span class="ml-2 font-weight-bold">Project Type</span>
                     <span class="mx-4 blue--text font-weight-bold">({{ project_cnt }} projects)</span>
                   </v-card-subtitle>
                   <v-card-text>
                     <v-row class="ml-1">
                     <v-checkbox v-for="ptype in projectTypes" :key="ptype.id"
                      v-model="selected_types"
                      :label="ptype.text"
                      :value="ptype.text"
                      class="mr-8"
                      hide-details
                      @click="update_filter = !update_filter"
                    ></v-checkbox> 
                    </v-row>
                   </v-card-text>
              </div>
              <v-divider class="mx-4"></v-divider> 
            
              <div class="mb-0 mt-1">
                <v-card-subtitle class="pb-0">
                     <span class="ml-2 font-weight-bold">Transport Mode</span>
                </v-card-subtitle>

                <v-card-text class="py-0">
                <v-select
                  v-model="selected_modes"
                  :items="modes"
                  label=""
                  multiple
                  @change="update_filter = !update_filter"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ selected_modes.length - 1 }} others)
                    </span>
                  </template>

                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggleMode"
                      @change="update_filter = !update_filter"
                    >
                      <v-list-item-action>
                        <v-icon :color="modes.length > 0 ? 'indigo darken-4' : ''">
                          {{ iconMode }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                </v-select>
                </v-card-text>

              </div>
              <!-- <v-divider class="mx-4"></v-divider>   -->

              <div class="my-0">
                <v-card-subtitle class="py-0">
                     <span class="ml-2 font-weight-bold">Key Technology</span>
                </v-card-subtitle>

                <v-card-text class="py-0">
                <v-select
                  v-model="selected_techs"
                  :items="key_techs"
                  label=""
                  multiple
                  @change="update_filter = !update_filter"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ selected_techs.length - 1 }} others)
                    </span>
                  </template>

                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggleTech"
                      @change="update_filter = !update_filter"
                    >
                      <v-list-item-action>
                        <v-icon :color="key_techs.length > 0 ? 'indigo darken-4' : ''">
                          {{ iconTech }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                </v-select>
                </v-card-text>

              </div>
              <!-- <v-divider class="mx-4"></v-divider>             -->

              <div>
                <v-card-subtitle class="py-0">
                     <span class="ml-2 font-weight-bold">Region</span>
                  </v-card-subtitle>
                
                <v-card-text class="py-0">
                <v-select
                  v-model="selected_regions"
                  :items="regions"
                  label=""
                  multiple
                  :menu-props="{ top: false, offsetY: true }"
                  @change="update_filter = !update_filter"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ selected_regions.length - 1 }} others)
                    </span>
                  </template>

                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle_region"
                      @change="update_filter = !update_filter"
                    >
                      <v-list-item-action>
                        <v-icon :color="regions.length > 0 ? 'indigo darken-4' : ''">
                          {{ iconRegion }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                
                </v-select>
                </v-card-text>

              </div>  

              <div>
                <v-card-subtitle class="py-0">
                     <span class="ml-2 font-weight-bold">Keywords Search</span>
                  </v-card-subtitle>
                
                <v-card-text class="py-0">
                <v-text-field 
                  v-model='keywords_search'
                  label="Type to search"
                  @input="update_filter = !update_filter"
                  ></v-text-field>
                </v-card-text>

              </div>  

  
            </v-card>

          </v-list-group>        
        </v-list>
      </v-navigation-drawer>
       
       <!-- footer -->        
      <v-card-actions v-if="!mini" class="p-0 my-0" style="height: 15vh;">
        <div class="font-weight-bold" style="font-family: 'Open Sans', sans-serif;">
          <div>
            <v-img  
              contain
              height="6vh"
              :src="domain + '/static/images/transport-logo.png'"
            ></v-img>
          </div>
          <div class="primary--text ml-16 font-weight-bold" style="font-size: 10pt;">ITRGK - Global Knowledge </div>
          <div class="primary--text ml-16 font-weight-bold" style="font-size: 10pt;">
            Contact: <a href="mailto:wqiao@worldbank.org">wqiao@worldbank.org</a>
          </div>
        </div>
        <!-- <v-spacer></v-spacer> -->
      </v-card-actions>
    </v-card>

    <v-col class="px-0 py-0">    
      <Mapview 
      :update_filter="update_filter"
      :selected_types="selected_types"
      :selected_modes="selected_modes"
      :selected_techs="selected_techs"
      :selected_regions="selected_regions"
      :keywords_search="keywords_search"
      style="height: 100%; width: 100%;" />   
    </v-col>

  </v-row>

</template>


<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
</style>


<script>
  import Mapview from '@/components/Mapview';
  // import axios from 'axios';

  export default {
    components:{
      Mapview,
    },
    data () {
      return {
        loading: false,
        drawer: true,        
        mini: false,
        date: new Date().toISOString().substr(0, 7),
        menu: false,
        modal: false,
        domain: this.$domain,
        project_cnt: null,
        menu_items: [
          { title: 'DATASETS', icon: 'mdi-database' },
          { title: 'ANALYSES', icon: 'mdi-head-dots-horizontal' },
          { title: 'DRONE', icon: 'mdi-quadcopter' },
        ],
        // project types
        update_filter: null,
        selected_types: ['ASA', 'Lending'],
        projectTypes: [
          {'id': 'p002', 'text': "Lending", 'color': 'orange'},
          {'id': 'p001', 'text': "ASA", 'color': 'blue'},
        ],
        // transport modes
        selected_all_modes: true,
        selected_modes: ['Road', 'Transit', 'Railway', 'Maritime', 'Aviation'],
        modes: [
          {'id': 'm001', 'text': "Road", 'color': '#FB8500'},
          {'id': 'm002', 'text': "Transit", 'color': '#00B050'},
          {'id': 'm003', 'text': "Railway", 'color': '#7030A0'},
          {'id': 'm004', 'text': "Maritime", 'color': '#0070C0'},
          {'id': 'm005', 'text': "Aviation", 'color': '#00B4D8'},
        ],
        selected_techs: ["Big data", "Digitalization", "UAV","E-mobility", "ITS", "ICT"],
        key_techs: [
          {'id': 't006', 'text': "Big data", 'icon': "mdi-database-search", 'color': 'red'},
          {'id': 't001', 'text': "Digitalization", 'icon': "mdi-counter", 'color': '#1d3557'},
          {'id': 't007', 'text': "E-mobility", 'icon': "mdi-car-electric", 'color': '#52b788'},
          {'id': 't005', 'text': "ICT", 'icon': "mdi-car-wireless", 'color': '#219ebc'},
          {'id': 't004', 'text': "ITS", 'icon': "mdi-monitor-cellphone-star", 'color': '#f07167'},
          {'id': 't003', 'text': "UAV", 'icon': "mdi-quadcopter", 'color': '#0077b6'},
        ],
        selected_regions: ['Global', 'SAR', 'AFR', 'ECA', 'EAP', 'LAC', 'MNA'],
        regions: [
          {'id': 'r001', 'text': "Global"},
          {'id': 'r002', 'text': "AFR"},
          {'id': 'r003', 'text': "EAP"},
          {'id': 'r004', 'text': "ECA"},
          {'id': 'r005', 'text': "LAC"},
          {'id': 'r006', 'text': "MNA"},
          {'id': 'r007', 'text': "SAR"},
        ],
        selected_img: null,
        keywords_search: "",
      }
    },
    computed: {
      // select all/some of modes
      selectAllModes () {
        console.log(this.selected_modes.length, "VS", this.modes.length)
        return this.selected_modes.length === this.modes.length
      },
      selectSomeModes () {
        return this.selected_modes.length > 0 && !this.selectAllModes
      },
      iconMode () {
        if (this.selectAllModes) return 'mdi-close-box'
        if (this.selectSomeModes) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },

      // select all/some of techs
      selectAllTechs () {
        return this.selected_techs.length === this.key_techs.length
      },
      selectSomeTechs () {
        return this.selected_techs.length > 0 && !this.selectAllTechs
      },
      iconTech () {
        if (this.selectAllTechs) return 'mdi-close-box'
        if (this.selectSomeTechs) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },

      // select all/some of regions
      selectAllRegions () {
        return this.selected_regions.length === this.regions.length
      },
      selectSomeRegions () {
        return this.selected_regions.length > 0 && !this.selectAllRegions
      },
      iconRegion () {
        if (this.selectAllRegions) return 'mdi-close-box'
        if (this.selectSomeRegions) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    async created () {
     
    },
    methods: {      
       // select all modes
      toggleMode() {
          this.$nextTick(() => {
          if (this.selectAllModes) {
            this.selected_modes = []
          } else {
              this.selected_modes = []
              this.modes.forEach(ele => {
              this.selected_modes.push(ele['text'])
            })
          }
        })
      },
      // tech dropdown
      toggleTech () {
        this.$nextTick(() => {
          if (this.selectAllTechs) {
            this.selected_techs = []
          } else {
              this.selected_techs = []
              this.key_techs.forEach(ele => {
              this.selected_techs.push(ele['text'])
            })
          }
        })
      },

      // region dropdown
      toggle_region () {
        console.log('all region')
        this.$nextTick(() => {
          if (this.selectAllRegions) {
            this.selected_regions = []
          } else {            
              this.selected_regions = []
              this.regions.forEach(ele => {
              this.selected_regions.push(ele['text'])
            })
          }
        })
      },

      
    }
  }
</script>