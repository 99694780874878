import Vue from 'vue'
import Router from 'vue-router'
// import About from '@/components/About'
import Mapview from '@/components/Mapview'
import Test from '@/components/Test'

Vue.use(Router)

export default new Router({
    base: process.env.BASE_URL,
    routes: [{
            path: '/map',
            name: 'mapview',
            component: Mapview
        },     
        {
            path: '/test',
            name: 'test',
            component: Test
        }
    ]
})