import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import 'leaflet/dist/leaflet.css';
// import '@/assets/static/css/app.scss';
// import 'bootstrap';
// import '@/assets/static/css/bootstrap.min.css';

Vue.config.productionTip = false
// Vue.prototype.$domain = 'http://localhost:5000'
Vue.prototype.$domain = "https://wb-engine.winbyai.com"

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
