<template>
<l-map style="height: 800px" :zoom="zoom" :center="center">
<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
<l-geo-json :geojson="geojson" ></l-geo-json>
</l-map>
</template>

<script>
import {LMap, LTileLayer, LGeoJson} from 'vue2-leaflet';

export default {
   name: "Test",
  components: {
    LMap,
    LTileLayer,
    LGeoJson
  },
  data () {
    return {
      url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      zoom: 7,
    //   center: [-35.739337, 174.346433],
      center: [ 38.8822811904752, -77.0915135159643],
      geojson: null,    
    
    };
  },
  async created () {
    const response = await fetch('http://127.0.0.1:5000/static/geojson/routes/baytransit.geojson');
    this.geojson = await response.json()
  }
}
</script>
