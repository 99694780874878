<template>
  <div>   
    <l-map
      ref="map"   
      @ready="getMapBounds"
      @moveend="getMapBounds"
      :zoom="zoom" :center="center" :options="{zoomControl:false}"> 
      <l-control-zoom position="bottomleft"></l-control-zoom>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <v-marker-cluster>
        <l-marker
          v-for="circle in dataCircles"
          :key="circle.Title + circle.PNumber"
          :lat-lng="[circle.Lat, circle.Lon]"
          :icon="addIcon(circle.Mode)"
        >
          <l-popup :autoPanPadding="(-200, -900)"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  {{ circle.Title }}
                  <span class="blue--text text--darken-4">
                    <span v-if="circle.PNumber"> ({{ circle.PNumber }}) </span>
                    <!-- <span v-else class="red--text">N/A</span> -->
                  </span>                
                </h5>

                <div class="card-text">
                  <span>
                    {{ circle.Abstract }}
                  </span>
                </div>
                
                <ul class="list-group list-group-flush">
                  <!-- 1st row -->
                  <li class="list-group-item row mt-1">
                    <v-row class="mt-1 ml-1">
                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-wan</v-icon> Region
                      </v-col>
                      <v-col cols="5" class="text-left">
                        <span v-if="circle.Region">{{ circle.Region }}</span>
                        <!-- <span v-else>N/A</span> -->
                        <span class="mr-2" v-if="circle.Country && circle.Region">, </span>
                        <span v-if="circle.Country">{{ circle.Country }}</span>
                        <!-- <span v-else>N/A</span> -->
                        <span class="mx-4"> </span>
                      </v-col>
                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-calendar</v-icon> Year
                      </v-col>
                      <v-col cols="3">
                        <span v-if="circle.Year">{{ circle.Year }}</span>
                        <span v-else>N/A</span>
                      </v-col>
                    </v-row>
                  </li>

                  <!-- 2nd row -->
                  <li class="list-group-item row mt-3">
                    <v-row class="ml-1">
                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-train-car </v-icon> Mode
                      </v-col>
                      <v-col cols="5">
                        <span v-if="circle.Mode">{{ circle.Mode.join(", ") }}</span>
                        <span v-else>N/A</span>
                      </v-col>
                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-link-variant</v-icon>
                        Link</v-col>
                      <v-col cols="3">
                        <a
                          :href="circle.Link"
                          target="_blank"
                          v-if="circle.Link"
                          >Click to Visit</a
                        >
                        <span v-else>N/A</span>
                      </v-col>
                    </v-row>
                  </li>
                 
                 <!-- 3rd row -->
                  <li class="list-group-item row mt-3">
                    <v-row class="ml-1">
                      <v-col cols="2" class="font-weight-bold" title="Project Type">
                        <v-icon>mdi-shape</v-icon>
                        Type</v-col>
                      <v-col cols="5" title="Project Type">
                        <div v-if="circle.Category !== circle.ChildCategory">
                          {{circle.Category}}-{{circle.ChildCategory}}
                        </div>
                        <div v-else>
                          {{circle.Category}}
                        </div>
                      </v-col>
                      
                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-laptop</v-icon>Key Tech.
                      </v-col>
                      <v-col cols="3">
                      <span v-if="circle.KeyTech" class="blue--text text--darken-4">{{ circle.KeyTech.join(", ") }}</span>
                      <span v-else>N/A</span>
                    </v-col> 
                    </v-row>
                  </li>
                  
                  <!-- 4th row -->
                  <li class="list-group-item row mt-3">
                    <v-row class="ml-1">
                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-magnify-expand</v-icon>
                        Key Words</v-col>
                      <v-col cols="5">
                        <span v-if="circle.KeyWords">{{circle.KeyWords}}</span>
                        <span v-else>N/A</span>
                      </v-col>

                      <v-col cols="2" class="font-weight-bold">
                        <v-icon>mdi-currency-usd</v-icon>Investment:
                      </v-col>
                      <v-col cols="3">
                        Tech:
                        <span v-if="circle.TechnologyRelated" class="blue--text text--darken-4">
                          {{ circle.TechnologyRelated}} 
                        </span>
                        <span v-else>N/A</span>
                        <br> 
                      Total: 
                      <span v-if="circle.TotalInvestment">
                        {{ circle.TotalInvestment}}
                      </span>
                      <span v-else>N/A</span>
                    </v-col> 
                    </v-row>
                  </li>
                  
                  <!-- 5th row -->
                  <li class="list-group-item row mt-6" v-if="!circle.StrikingPhots">
                    <v-row class="px-1">
                      <v-col cols="4" >
                        <v-img
                          max-width="250"
                          :src="domain + '/static/images/projects/' + circle.StrikingPhotos"
                          @click="zoomImg(domain + '/static/images/projects/' + circle.StrikingPhotos)"
                        ></v-img>
                        <span class="font-italic text-caption" v-if="circle.PhotoDesc">{{circle.PhotoDesc}}</span>
                      </v-col>
                      <v-col cols="8" >
                        <div class="font-weight-bold">Key Messages:</div>
                        <div v-if="circle.KeyTakeaways">
                          <ul v-for="kt in circle.KeyTakeaways.split('\n')"
                          :key="kt"                            
                          >
                            <li v-if="kt">{{ kt }}</li>
                          </ul>
                        </div>
                      </v-col>
                    </v-row>
                  </li>
                </ul>
              </div>
            </div>
          </l-popup>
        </l-marker>
      </v-marker-cluster>



     <l-control position="bottomright" >
       <v-card
          max-width="200"
          class="mx-auto"
          outlined
        >         
          <v-list dense>
              <v-list-item  v-for="item in legends" :key="item.Mode">
                <v-list-item-icon style="height: 28px">
                  <v-img :src="item.src" max-height="30" max-width="30"></v-img>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.Mode"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list>
        </v-card>
    </l-control>
    </l-map>
  </div>
</template>

<style>
@import "../assets/static/css/app.scss";
@import "../assets/static/css/MarkerCluster.css";
</style>

<script>

import "leaflet";
import { icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LControl,
  // LGeoJson,
  LMarker,
  LPopup,
  LControlZoom
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import axios from "axios";

// delete Icon.Default.prototype._getIconUrl;
// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });

export default {
  name: "Mapview",
  props: ["update_filter", "selected_types", "selected_modes", "selected_techs", "selected_regions", "keywords_search"],
  components: {
    LMap,
    LTileLayer,
    // LControlLayers,
    // LGeoJson,
    LControl,
    LMarker,
    LPopup,
    LControlZoom,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      loading: false,
      zoom: 2,
      center: [0, 0],
      country_geojson: null,
      tileProviders: [
        {
          name: "OpenStreet",
          visible: true,
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        },
      ],
      dataCircles: [],
      tooLong: true,
      icon: null,
      legends: [
        {"Mode":'Road', "src": this.$domain + "/static/images/Road.png"},
        {"Mode":'Transit', "src": this.$domain + "/static/images/Transit.png"},
        {"Mode":'Railway', "src": this.$domain + "/static/images/Railway.png"},
        {"Mode":'Maritime', "src": this.$domain + "/static/images/Maritime.png"},
        {"Mode": 'Aviation', "src": this.$domain + "/static/images/Aviation.png"},
      ],
      domain: this.$domain,
      mapBounds: null
    };
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    optionsPoints() {
      return {
        onEachFeature: this.onEachPointFn,
      };
    },
    styleFunction() {
      return () => {
        return {
          weight: 1,
          color: "white",
          opacity: 0.8,
          fillColor: "blue",
          fillOpacity: 0.8,
        };
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        const a = {
          name1: "value111",
          name2: 111111111,
        };
        var ht =
          '<div class="card">' +
          '<div class="card-body">' +
          '<h5 class="card-title">' +
          feature.properties.FORMAL_EN +
          "</h5>" +
          '<p class="card-text">' +
          "Some quick example text to build on the card title and make up the bulk of the" +
          "cards content." +
          "</p>" +
          '<ul class="list-group list-group-flush">';
        Object.entries(a).forEach(([k, v]) => {
          ht +=
            '<li class="list-group-item row">' +
            '<div class="col-2">' +
            k +
            ": </div>" +
            '<div class="col-10">' +
            v +
            "</div>" +
            "</li>";
        });
        ht +=
          '<li class="list-group-item row">' +
          '<div class="col-2">' +
          "Image: " +
          "</div>" +
          '<div class="col-10">' +
          '<img src="https://media.pixeltuner.de/wp-content/uploads/2018/06/aurora-1197755_640.jpg" alt="" style="max-width: 200px;">' +
          "</div>" +
          "</li>";

        ht += "</ul>" + "</div>" + "</div>";

        layer.bindPopup(ht, { permanent: false, sticky: true });

        layer.bindTooltip(feature.properties.NAME_EN, {
          permanent: false,
          sticky: false,
          direction: "center",
        });
      };
    },
    onEachPointFn() {
      // return null;
      return (feature, layer) => {
        console.log(feature, layer);
        
        // var ht = '<div class="card">' +
        //   '<div class="card-body">' +
        //     '<h5 class="card-title">'+ feature.properties.PNumber + ": " +
        //     feature.properties.Title +'</h5>' +
        //     '<p class="card-text">' + feature.properties.Abstract +
        //     '</p>'+
        //     '<ul class="list-group list-group-flush">'
        // ht += '<li class="list-group-item row">'+
        //   '<div class="col-2">'+ 'Country' +': </div>' +
        //   '<div class="col-10">'+ feature.properties.Country +'</div>' +
        //   '</li>'
        // ht += '<li class="list-group-item row">'+
        //   '<div class="col-2">'+ 'Country' +': </div>' +
        //   '<div class="col-10">'+ feature.properties.Country +'</div>' +
        //   '</li>'
        // ht += '<li class="list-group-item row">'+
        //   '<div class="col-2">'+ 'Country' +': </div>' +
        //   '<div class="col-10">'+ feature.properties.Country +'</div>' +
        //   '</li>'
        // ht +=  '<li class="list-group-item row">'+
        //   '<div class="col-2">'+ 'Image: ' +'</div>' +
        //   '<div class="col-10">'+
        //     '<img src="" alt="'+ feature.properties.StrikingPhotos +'" style="max-width: 200px;">'+
        //   '</div>' +
        //   '</li>'

        // ht += '</ul>'+
        //   '</div>'+
        // '</div>'

        // layer.bindPopup(
        //   "feature.properties.PNumber",
        //   { permanent: false, sticky: true }
        // );        
      };
    },

  },
  methods: {
    zoomImg (img_url){
      this.selected_img = img_url

    },
    styleKeyNumbers(textStr){
      const nums = textStr.match(/\$*\d+(,*\d{3,})*(\.*\d+)*%*/gm)
      console.log(nums)
      if(!nums){
        return textStr
      }

      for(const n of nums){
        textStr = textStr.replaceAll(n, ("<span class='font-italic font-weight-bold'>"+ n +"</span>"))
      }
      return textStr
    },
    addIcon(Mode){
      const m = Mode.length > 1 ? Mode[0]: Mode 
      return icon({
      iconUrl:this.$domain + "/static/images/"+ m +".png",
      iconSize: [32, 32],
      iconAnchor: [16, 37],
      popupAnchor: [0, -40]
    })
    },
    generateColor(n) {
      if (n.length < 8) {
        n = n * 10 ** (8 - n.length);
      } else {
        n = n / 10 ** (n.length - 8);
      }
      return "#" + Math.floor(n / 5).toString(16);
    },
    encodeStr(text_str) {
      var result = "";
      for (var i = 0; i < text_str.length; i++) {
        var code = text_str.charCodeAt(i);
        result += code;
      }
      return result;
    },
    async getGeojson() {
      const response = await fetch(this.$domain + "/map/get_country_borders");
      // const response = await fetch('https://wb-engine.winbyai.com/map/get_country_borders');
      this.country_geojson = await response.json();
    },
    async getProjectCoords() {
      console.log(this.selected_types)
      console.log(this.selected_techs)
      const path = this.$domain + "/map/get_project_list";
      axios.post(
        path,
        {
          'project_types': this.selected_types,
          'modes': this.selected_modes,
          'key_techs': this.selected_techs,
          'regions': this.selected_regions,
          'keywords_search': this.keywords_search,
        }
      ).then((res) => {
        console.log(res.data);
        this.dataCircles = res.data;
        this.$parent.project_cnt = res.data.length
      });
    },
    getMapBounds () {
      console.log(this.$refs.map)
      console.log(this.$refs.map.mapObject.getBounds())
      // this.mapBounds = this.$refs.map;
      // console.log(this.mapBounds)
    },

},
  async created() {
    // this.getGeojson()
    this.getProjectCoords();
  },
  watch: {
     update_filter(){
       console.log("updating filter")
      this.getProjectCoords()
    }
  },
};
</script>