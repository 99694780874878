<template>
  <v-app>
    <Dashboard />   
    <v-main> 
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Dashboard from './components/Dashboard';

export default {
  name: 'App',

  components: {
    Dashboard
  },

  data: () => ({
  }),
};
</script>
